<template>
  <div class="box">
        <div class="list flex" :class="{'active': viewMore }">
            <div class="item" v-for="(item,idx) in list" 
                :class="{'active':tabIndex == idx}" 
                @click="tab(item,idx)" :key="idx">
                {{item.name}}
            </div>
        </div>
        <div @click="view_more()" class="viewMore noselect flex flex-align-center">
            <span>更多</span>
            <i class="iconfont icon-fanhuitubiao1" :class="{'active': viewMore }"></i>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'id',
        event: 'change',
    },
    props: ['id','list'],
    data() {
        return {
           newId: null,
           tabIndex: 0,
           viewMore: false,
        }
    },
    watch: {
        id:{
            handler() {
                this.newId = this.id;
            },
            deep: true
        }
    },
    methods: {
        onChange() {
            this.$emit("change", this.newId);
            console.log(this.newId);
        },
        tab(item,index) {
            // console.log(index);
            this.tabIndex = index;
            this.newId = item.id;
            this.onChange();
        },
        view_more() {
            this.viewMore = !this.viewMore;
        }
    }
};
</script>

<style scoped lang="less">
    .box {
        position: relative;
        .list {
            width: calc(100% - 100px);
            height: 30px;
            overflow: hidden;
            &.active {
                width: 95%;
                height: unset;
            }
        }
        .item {
            padding: 0 14px;
            height: 30px;
            line-height: 30px;
            border-radius: 16px;
            font-size: 14px;
            color: #4D4D4D;
            cursor: pointer;
            margin: 0 6px 6px;
            transition: all 0.3s;
            &.active {
                background: #5074EE;
                color: #fff;
            }
        }
        .viewMore {
            font-size: 14px;
            color: #4D4D4D;
            position: absolute;
            top: 4px; right: 0;
            cursor: pointer;
            i {
                font-size: 12px;
                display: inline-block;
                transform:rotate(90deg);
                -ms-transform:rotate(90deg); 	/* IE 9 */
                -moz-transform:rotate(90deg); 	/* Firefox */
                -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
                -o-transform:rotate(90deg); 	/* Opera */ 
                transition: all 0.3s;
                &.active {
                    transform:rotate(270deg);
                    -ms-transform:rotate(270deg); 	/* IE 9 */
                    -moz-transform:rotate(270deg); 	/* Firefox */
                    -webkit-transform:rotate(270deg); /* Safari 和 Chrome */
                    -o-transform:rotate(270deg); 	/* Opera */ 
                }
            }
        }
    }
</style>