<template>
    <div class="zt-page-content" style="min-width:1300px;">
        <div class="header">
            <div class="search-bar">
                <div class="width_1200 flex flex-align-center">
                    <div style="width:100px;font-size:18px;color:rgba(41,41,41,1);"><b>设计超市</b></div>
                    <el-input v-model="query.k" placeholder="餐桌椅 床 更多" style="max-width: 500px;margin-left: 50px;">
                        <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
            </div>
            <div class="tab_menu width_1200 flex">
                <div v-for="(item,idx) in tabs" :key="idx" @click="tab(idx)" class="menu_item" :class="{'cur':tabsIndex == idx}">
                    {{item.name}}
                </div>
            </div>
        </div>
        <div v-if="tabsIndex==0" class="filtra width_1200">
            <div class="tab_menu min flex">
                <div v-for="(item,idx) in tabList" :key="idx" @click="tab1(idx)" class="menu_item" :class="{'cur':tabIndex == idx}">
                    {{item.name}}
                </div>
            </div>
            <div class="filtra-box">
                <div class="select-wrap flex">
                    <label>风格：</label>
                    <div v-if="config.styles && config.styles.length>0" class="flex-1 flex">
                        <ItemCat v-model="query.style_no" :list="config.styles"></ItemCat>
                    </div>
                </div>
                <div v-if="tabIndex==0" class="select-wrap flex">
                    <label>类目：</label>
                    <div v-if="config.cats && config.cats.length>0" class="flex-1 flex">
                        <ItemCat v-model="query.cat_id" :list="config.cats"></ItemCat>
                    </div>
                </div>
                <div class="select-wrap flex flex-align-center">
                    <label>价格：</label>
                    <div class="flex-1 flex flex-align-center" style="margin-left:4px">
                        <el-input v-model="query.price_range[0]" placeholder="请输入价格" style="width:100px" size="mini"></el-input>
                        <span style="margin:0 15px">-</span>
                        <el-input v-model="query.price_range[1]" placeholder="请输入价格" style="width:100px" size="mini"></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="tabsIndex==0" class="content zt-block width_1200">
            <div class="head flex">
                <div class="flex flex-align-center">
                    <span @click="sort('time')" :class="{'active': query.sort_by!='price' }">上架时间</span>
                    <img @click="sort('up')" 
                        v-if="query.sort_by!='price' && query.sort_order!=1" 
                        src="@/assets/images/factory/sort_down.png">
                    <img @click="sort('down')" 
                        v-if="query.sort_by!='price' && query.sort_order==1" 
                        src="@/assets/images/factory/sort_up.png">
                </div>
                <div class="flex flex-align-center">
                    <span @click="sort('price')" :class="{'active': query.sort_by=='price' }">一口价</span>
                    <img @click="sort('up')" 
                        v-if="query.sort_by=='price' && query.sort_order!=1" 
                        src="@/assets/images/factory/sort_down.png">
                    <img @click="sort('down')" 
                        v-if="query.sort_by=='price' && query.sort_order==1" 
                        src="@/assets/images/factory/sort_up.png">
                </div>
            </div>
            <Single v-if="tabIndex==0 && hackReset" :query="query"></Single>
            <Series v-if="tabIndex==1 && hackReset" :query="query"></Series>
        </div>
        <div v-if="tabsIndex==1">
            <Fchz></Fchz>
        </div>
    </div>
</template>

<script>
    import { getCats, getStyles } from '@/service/item';
    import { fetchTree } from '@/util';
    import ItemCat from './ItemCat'; // 类目
    import Single from './single/Single'; // 单品作品
    import Series from './series/Series'; // 套系作品
    import Fchz from './fchz/Fchz'; // 套系作品
    export default {
        components: {
            ItemCat,
            Single, Series, Fchz
        },
        data() {
            return {
                config:{
                    styles: null, // 风格 
                    cats: null, // 类目
                },
                query:{
                    k: "", // 搜索关键字
                    style_no: "", // 风格编号列表
                    cat_id: "", // 类目id列表
                    price_range: ["",""], // 价格范围，数组两个元素表示价格高低范围，数组只有一个元素表示要筛选大于等于这个值的范围
                    sort_by: "", // 默认按发布时间排序，可以传price按价格排序
                    sort_order: "", // 默认降序，传1表示升序
                },
                tabs:[
                    {name:"设计版权购买"},
                    {name:"分成合作"},
                    // {name:"设计师展厅"}
                ],
                tabsIndex: 0,
                tabList:[
                    {name:"单品作品"},
                    {name:"套系作品"},
                ],
                tabIndex: 0,
                hackReset:true,//刷新子组件
                type: 0, // 作品类型 0单品作品，1套系作品
            }
        },
        watch: {
            query:{
                handler() {
                    this.handleChange();
                },
                deep: true
            }
        },
        async created() {
            if (this.$route.query.stype==1) {
                this.tabsIndex = 1
            }
            if (this.$route.query.type==1) {
                this.tabIndex = 1
            }
            this.catList = await getCats();
            this.config.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            this.config.cats = [{ id:"", name:"全部" }].concat(this.config.cats);
            console.log(this.config.cats);
            this.config.styles = [{ id:"", name:"全部" }].concat(await getStyles());
        },
        mounted() {},
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            // 选项卡-版权购买-分成合作
            tab(index) {
                this.tabsIndex = index;
                if (index == 1) {
                   this.$router.push({ query:{ stype: 1} });
                } else {
                    this.$router.push({ query:{ stype: 0} });
                }
                console.log(index);
            },
            // 选项卡-单品作品-套系作品
            tab1(index) {
                this.tabIndex = index;
                if (index == 1) {
                   this.$router.push({ query:{ type: 1} });
                } else {
                    this.$router.push({ query:{ type: 0} });
                }
                console.log(index);
            },
            search() {
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            sort(type) { // 排序
                if (type=="time") {
                    this.query.sort_by = "";
                    this.query.sort_order = "";
                } else if (type=="price") {
                    this.query.sort_by = "price";
                    this.query.sort_order = "";
                } else if (type=="up") {
                    this.query.sort_order = 1;
                } else if (type=="down") {
                    this.query.sort_order = "";
                }
            },
            handleChange() {
                console.log("this.query",this.query);
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            
        }
    }
</script>
<style scoped lang="less">
.width_1200 {
    width: 1200px;
    margin: 0 auto;
}
.header {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px #D1D9E2;
    .search-bar {
        padding: 40px 0 30px;
        border-bottom: 1px solid #F5F5F5;
    }
}
.tab_menu {
    .menu_item {
        height: 60px;
        line-height: 60px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: #4D4D4D;
        &.cur {
            color: #5074EE;
            border-bottom: 2px solid #5074EE;
        }
    }
    &.min .menu_item {
        height: 50px;
        line-height: 50px;
    }
}
/*----筛选----*/
.filtra {
    background: #fff;
    box-shadow: 0px 0px 4px 0px #CCCCCC;
    margin: 20px auto;
    .tab_menu {
        padding: 0 20px;
        border-bottom: 1px solid #F5F5F5;
    }
    .filtra-box {
        padding: 0 20px;
        .select-wrap {
            height: unset;
            padding:20px 0 14px;
            border-bottom: 1px solid #E6E6E6;
            label {
               line-height: 30px; 
            }
        }
    }
}
/*----内容----*/
.content {
    margin-bottom: 20px;
    .head {
        font-size: 14px;
        color: #4D4D4D;
        line-height: 20px;
        padding: 20px 20px 0;
        >div {
            cursor: pointer;
            margin-right: 35px;
            span {
                &.active {
                    color: #5074EE;
                }
            }
            img {
                width: 14px;
                height: 21px;
                margin-left: 6px;
            }
        }
    }
}
</style>