<template>
    <div class="box">
        <div v-if="items && items.length>0" class="list flex" style="min-height: 670px;">
            <div class="list-item" v-for="(item,idx) in items" :key="idx">
                <div @click="gotoDetail(item.id)" style="width:210px;height:210px;margin-right:40px;cursor:pointer;">
                    <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                    :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                </div>
                <div class="detail">
                    <div @click="gotoDetail(item.id)" class="item-name ellipsis">{{item.name}}</div>
                    <div class="item-series flex">
                        <span v-if="item.style">{{item.style.name}}</span>
                        <span v-if="item.style && item.cat" style="margin:0 8px"> | </span>
                        <span v-if="item.cat">{{item.cat.name}}</span>
                    </div>
                    <div style="margin-bottom:8px;" class="flex">
                        <div class="item-price">{{10000-item.price_percent | percentFilt}}%</div>
                        <div style="line-height:28px;margin-left:4px;">分成比例</div>
                    </div>
                    <div style="font-size:12px;margin-bottom:8px;" class="flex flex-align-center">
                        <!-- <div style="margin-right: 15px;">已有{{item.design_market_n_visited}}人查看</div> -->
                        <!-- <div><el-button @click.stop="gotoSign(item)" size="mini" type="primary">我要合作</el-button></div> -->
                    </div>
                    <div class="item-coop flex">
                        <div class="item-progress">
                            <div :style="{width:item.factory_count+'0%'}"></div>
                            <div style="position:absolute;top:0;left:0;">已合作:{{item.factory_count}}</div>
                        </div>
                        <div style="font-size:12px;color:#016FFF;">剩余名额：{{10-item.factory_count}}</div>
                    </div>
                    
                    
                    <div class="item-avatar flex flex-align-center">
                        <div style="width:24px;height:24px;border-radius:50%;overflow:hidden;margin-right:8px;">
                            <div v-if="item.design_comp.logo" class="bg_img"
                                :style="{backgroundImage: 'url(' + imgUrl+item.design_comp.logo + ')'}"></div>
                            <div v-else class="bg_img"
                                :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                        </div>
                        <div class="flex-1 ellipsis" v-if="item.design_comp.name">{{item.design_comp.name}}</div>
                    </div>

                </div>
            </div>
        </div>
        <div v-if="items && items.length==0" class="flex flex-center" style="text-align:center;min-height:400px;">
            <div>
                <img style="width:110px" :src="staticUrl+'/design/placeholder/meiyougengduo.png'" alt="">
                <div style="font-size:16px;color:#808080;padding:15px 0;">暂无相关数据</div>
            </div>
        </div>
        <div style="padding:10px 0 20px;text-align: left;">
            <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize" :total="total"></el-pagination>
        </div>

    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
    import { marketPercentList } from '@/service/factory';
    export default {
        components: {},
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                popVisible: false,
                popTitle: null,
                queryOpt: {
                    pagesize: 20,
                    page: 1,
                    sort_by: 0,
                    sort_dir: 0,
                    cat_ids: [],
                },
                items: null,
                total:0,
            }
        },
        watch: {
            queryOpt: {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        async created() {
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
        },
        mounted() {
            this.refreshItems();
        },
        methods: {
            goto(path){
                this.$router.push(path);
            },
            gotoDetail(id) {
                this.$router.push("/factory/gmsj/fchz/detail/"+id);
            },
            gotoSign(item) {
                this.$router.push("/factory/hzzpQuote/"+item.id);
                let obj = {
                    name:item.name,
                    cat:item.cat.name,
                    set:item.set.name,
                }
                sessionStorage.setItem("quoteInfo",JSON.stringify(obj));
            },
            refreshItems() {
                console.log('refreshItems');
                marketPercentList(this.queryOpt).then(rst => {
                    this.total=rst.total;
                    this.items = rst.list;
                    console.log(this.items);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.box {
    width: 1200px;
    background: #fff;
    margin: 20px auto;
}
.list {
    padding: 10px;
    .list-item{
        width: 212px;
        height: 380px;
        border:1px solid rgba(204,204,204,1);
        margin: 10px;
        /* transform: scale(1);
        transition: all 0.3s; */
        &:hover {
        /* border: 1px solid rgba(80, 116, 238, 1);
        transform: scale(1.064); */
        }
        .detail {
            padding:8px 13px;
            position: relative;
            font-size: 14px;
            color: #808080;
        }
        .item-name {
            font-size: 16px;
            font-weight: bold;
            color: rgba(41, 41, 41, 1);
            line-height:22px;
            margin-bottom: 4px;
            cursor:pointer;
        }
        .item-series {
            font-size: 12px;
            color: #B3B3B3;
            line-height: 16px;
            margin-bottom: 12px;
        }
        .item-price {
            font-size: 20px;
            color: #EE1818;
            line-height: 28px;
        }
        .item-progress {
            width: 91px;
            height: 20px;
            line-height: 20px;
            background: #E6E6E6;
            font-size: 12px;
            color: #fff;
            position: relative;
            margin-right: 10px;
            margin-bottom: 8px;
        }
        .item-progress>div:first-child {
            height: 20px;
            background: #E6A23C;
        }
        .item-avatar {
            border-top: 1px solid #E6E6E6;
            padding-top: 4px;
        }
    }
}
    
</style>